<template>
  <div>
    <div style="display: flex">
      <div class="storeList" style="width: 11%">
        <div class="tabTitle">
          <div class="span"></div>
          门店
        </div>
        <div style="min-height: 500px; max-height: 700px; overflow-y: auto">
          <div
            class="store"
            v-for="(item, index) in storeList"
            v-bind:key="index"
            :class="hierarchy_id === item.hierarchy_id ? 'selectStore' : null"
            @click="handleStore(item)"
          >
            <div class="text">{{ item.hierarchy_name }}</div>
          </div>
        </div>
      </div>
      <div class="content" style="width: 88%">
        <div class="tableTop">
          <div class="tabTitle" style="float: left">
            <div class="span"></div>
            公众号列表
          </div>
          <el-button
            size="medium"
            type="primary"
            style="float: right; margin-right: 20px"
            @click="handleAdd"
            >新增公众号</el-button
          >
        </div>

        <div class="table">
          <el-table :data="tableData" stripe>
            <el-table-column
              align="center"
              prop="name"
              label="公众号名称"
            ></el-table-column>
            <el-table-column align="center" prop="status_text" label="状态">
            </el-table-column>
            <el-table-column
              align="center"
              prop="add_user_name"
              label="添加人"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="create_time"
              label="添加时间"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="operation"
              label="操作"
              width="200"
            >
              <template slot-scope="scope">
                <div class="options">
                  <el-select
                    v-model="scope.row.select"
                    placeholder="请选择"
                    @change="handleDetails($event, scope.row)"
                  >
                    <el-option label="编辑" :value="1"></el-option>
                    <el-option label="删除" :value="2"></el-option>
                  </el-select>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="visiable"
      :title="type === 'add' ? '新增公众号' : '编辑公众号'"
      :destroy-on-close="true"
      :before-close="cancel"
    >
      <el-form :model="weChatForm" :rules="rules" ref="weChatForm">
        <div class="form">
          <el-form-item
            label="公众号名称"
            :label-width="formLabelWidth"
            prop="name"
          >
            <el-input
              v-model="weChatForm.name"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入公众号名称"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="公众号原始id" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.original_id"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入公众号原始id"
            ></el-input>
          </el-form-item> -->
          <el-form-item
            label="APPID（公众号）"
            :label-width="formLabelWidth"
            prop="AppID"
          >
            <el-input
              v-model="weChatForm.AppID"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入APPID（公众号）"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="App Secret"
            :label-width="formLabelWidth"
            prop="AppSecret"
          >
            <el-input
              v-model="weChatForm.AppSecret"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入App Secret"
            ></el-input>
          </el-form-item>
          <el-form-item label="商户号" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.mch_id"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入商户号"
            ></el-input>
          </el-form-item>
          <el-form-item label="支付秘钥" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.key"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入支付秘钥"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书公钥" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.apiclient_cert"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入证书公钥"
            ></el-input>
          </el-form-item>
          <el-form-item label="证书私钥" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.apiclient_key"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入证书私钥"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="微信号" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.wechat"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入微信号"
            ></el-input>
          </el-form-item>
          <el-form-item label="URL" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.url"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入URL"
            ></el-input>
          </el-form-item>
          <el-form-item label="Token" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.token"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入Token"
            ></el-input>
          </el-form-item>
          <el-form-item label="EncodingAESKey" :label-width="formLabelWidth">
            <el-input
              v-model="weChatForm.aes_key"
              autocomplete="off"
              style="width: 375px"
              placeholder="请输入EncodingAESKey"
            ></el-input>
          </el-form-item>
          <el-form-item label="二维码" :label-width="formLabelWidth">
            <div style="display: flex; flex-direction: row">
              <el-upload
                class="avatar-uploader"
                action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                :data="{
                  directory: 'AGENT_INFO',
                }"
                :headers="{
                  authorization: token,
                }"
                :show-file-list="false"
                :on-success="handleQRcodeSuccess"
              >
                <img v-if="qrCode_img" :src="qrCode_img" class="avatarOther" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item> -->
          <el-form-item
            label="状态"
            :label-width="formLabelWidth"
            prop="status"
          >
            <el-switch
              v-model="weChatForm.status"
              :active-value="10"
              :inactive-value="20"
              active-color="#2791ff"
              inactive-color="#dcdcdc"
            >
            </el-switch>
          </el-form-item>
        </div>
        <div style="text-align: right">
          <el-button
            type="primary"
            @click="submit('weChatForm')"
            :loading="loading"
            >保存</el-button
          >
          <el-button @click="cancel">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getMerchantListTwo } from "@/api/goods/goodsStore/list";
import {
  weChatSetList,
  weChatSetAdd,
  weChatSetUpdate,
  weChatSetDel,
} from "@/api/member/integralSetting/index";
import Cookies from "js-cookie";
import storage from "good-storage";
export default {
  data() {
    return {
      type: "add",
      loading: false,
      visiable: false,
      storeList: [],
      hierarchy_id: 0,
      tableData: [],
      weChatForm: {},
      store_domain_id: 0,
      token: "",
      formLabelWidth: "150px",
      qrCode_url: "",
      qrCode_img: "",
      rules: {
        name: [
          { required: true, message: "请填写公众号名称", trigger: "blur" },
        ],
        original_id: [
          { required: true, message: "请填写公众号原始ID", trigger: "blur" },
        ],
        AppID: [{ required: true, message: "请填写AppID", trigger: "blur" }],
        AppSecret: [
          { required: true, message: "请输入App Secret", trigger: "blur" },
        ],
        wechat: [{ required: true, message: "请输入微信号", trigger: "blur" }],
        mch_id: [{ required: true, message: "请输入商户号", trigger: "blur" }],
        key: [{ required: true, message: "请输入支付秘钥", trigger: "blur" }],
        apiclient_cert: [
          { required: true, message: "请输入证书公钥 ", trigger: "blur" },
        ],
        apiclient_key: [
          { required: true, message: "请输入证书私钥 ", trigger: "blur" },
        ],
        url: [{ required: true, message: "请输入url", trigger: "blur" }],
        token: [{ required: true, message: "请输入token", trigger: "blur" }],
        aes_key: [
          { required: true, message: "请输入EncodingAESKey", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");

    this.getMerchantListReq();
  },
  methods: {
    /*
     *  获取门店数据
     */
    getMerchantListReq() {
      getMerchantListTwo().then((res) => {
        if (res.code === 1) {
          this.storeList = res.data;
          this.hierarchy_id = res.data[0].hierarchy_id;
          this.getList();
        }
      });
    },
    /*
     *  选择门店
     */
    handleStore(item) {
      this.hierarchy_id = item.hierarchy_id;
      this.getList();
    },
    /*
     *  公众号数据
     */
    getList() {
      const data = {
        hierarchy_id: this.hierarchy_id,
      };
      weChatSetList(data).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
        }
      });
    },
    /*
     *  关闭弹窗
     */
    cancel() {
      this.visiable = false;
      this.weChatForm = {};
      this.qrCode_url = "";
      this.qrCode_img = "";
      this.getList();
    },
    /*
     *  删除
     */
    handleDelete() {
      this.$confirm("此操作将删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          weChatSetDel({
            store_domain_id: this.store_domain_id,
          }).then((res) => {
            if (res.code === 1) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /*
     *  操作
     */
    handleDetails(event, row) {
      this.store_domain_id = row.store_domain_id;
      if (event === 1) {
        this.type = "edit";
        this.visiable = true;
        this.qrCode_url = row.qr_code;
        this.qrCode_img = row.qr_code;
        this.weChatForm = row;
      } else if (event === 2) {
        this.handleDelete();
      }
    },
    /*
     *  保存信息
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = this.weChatForm;
          data.qr_code = this.qrCode_url;
          data.hierarchy_id = this.hierarchy_id;
          if (this.type === "add") {
            weChatSetAdd(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
                this.cancel();
              }
            });
          } else {
            weChatSetUpdate(data).then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "更新成功!",
                });
                this.cancel();
              }
            });
          }
        }
      });
    },
    /*
     *  二维码上传
     */
    handleQRcodeSuccess(res, file) {
      this.qrCode_url = res.data;
      this.qrCode_img = URL.createObjectURL(file.raw);
    },
    /*
     *  新增公众号信息
     */
    handleAdd() {
      this.visiable = true;
      this.type = "add";
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .storeList {
  padding-top: 15px;

  margin-right: 10px;
  background: #ffffff;
  height: 90vh;
  border: 1px solid #ddd;
  border-radius: 6px;

  .store {
    margin-left: 17px;
    width: 182px;
    height: 40px;
    background: white;
    line-height: 40px;
    font-size: 14px;

    .text {
      margin-left: 15px;
    }
  }

  .selectStore {
    background-color: #ecf5ff;
    .text {
      margin-left: 14px;
    }
  }
}

/deep/ .avatar-uploader .el-upload {
  width: 100px;
  height: 100px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
/deep/ .avatar {
  width: 290px;
  height: 170px;
  display: block;
}
/deep/ .avatarOther {
  width: 100px;
  height: 100px;
  display: block;
}

/deep/ .auditSuccess {
  padding-bottom: 20px;
  margin-top: 10px;
  height: 450px;
  background-color: white;

  /deep/ .auditImg {
    margin: 10px auto 0 45%;
    width: 169px;
    height: 142px;
  }

  /deep/ .audit_text {
    width: 100%;
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;

    span {
      font-size: 22px;
      color: #5493f5;
    }
  }
}
.tabTitle {
  position: relative;
  padding-left: 5px;
  margin-left: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  height: 35px;
  line-height: 35px;
  font-size: 16px;

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 15px;
    background: #2791ff;
    left: -8px;
    top: 10px;
    border-radius: 99px;
  }
}

.tableTop {
  height: 45px;
  background: linear-gradient(0deg, #f3f9ff, #ffffff);
  padding-top: 10px;
  border: 1px solid #ddd;
  border-radius: 6px 6px 0px 0px;
}
</style>